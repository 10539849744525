import FluroContent from "./fluro-content";

class Contact extends FluroContent {
    constructor() {
        super("contact");
    }
}

const _Contact = new Contact();
export default _Contact;
