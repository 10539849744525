<template>
    <div class="settings full-width full-height overflow-y-scroll">
        <v-container class="mb-6 pa-6" style="max-width: 768px">
            <v-row class="mb-3">
                <v-col cols="12">
                    <h3>Settings</h3>
                </v-col>
            </v-row>

            <template v-if="!loading">
                <profile-form v-model="model" :allowEmailChange="false" :loading="saving"></profile-form>

                <v-row>
                    <v-col cols="12">
                        <v-btn color="primary" :loading="saving" :disabled="!isValid" @click="save" small>Save</v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </div>
</template>

<script>
import Persona from "@/modules/app-module/persona";
import Contact from "@/modules/app-module/contact";

import ProfileForm from "@/components/layout/profile-form.vue";

export default {
    name: "settings",

    meta: {
        title: "Settings",
    },

    props: {},

    components: {
        ProfileForm,
    },

    data() {
        return {
            errorText: "",
            loading: false,
            saving: false,
            processing: false,
            disabled: false,
            signupSent: false,
            error: false,
            model: {},

            options: [
                {
                    title: "Print Awards",
                    key: "print",
                },
                {
                    title: "Digital Awards",
                    key: "digital",
                },
            ],

            rules: {
                required: (value) => !!value || "This field is required",
                minLength3: (value) => value.length >= 3 || "Min 3 characters",
                minLength4: (value) => value.length >= 4 || "Min 4 characters",
                minLength6: (value) => value.length >= 6 || "Min 6 characters",
                isMatch: (value) => value === this.confirmPassword,
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    computed: {
        isValid() {
            let model = this.model;

            // if (!model || !model.type || !model.type.length) return false;

            if (!model || !model.firstName || !model.firstName.length) return false;

            if (!model || !model.lastName || !model.lastName.length) return false;

            if (!model || !model.email || !model.email.length) return false;

            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!pattern.test(model.email)) return false;

            if (!model || !model.phoneNumber || !model.phoneNumber.length) return false;

            if (!model || !model.address || !model.address.length) return false;

            if (!model || !model.country || !model.country.length) return false;

            return true;
        },
    },

    methods: {
        hasKey(key) {
            return this.model.type.some((type) => type && type.key == key);
        },
        async init() {
            this.loading = true;

            try {
                await this.$fluro.resetCache();

                let user = this.$app.user;

                let contact = await this.$fluro.api
                    .post("/content/_query", { _type: "contact", "data.personaId": user.persona })
                    .then(({ data }) => data);
                contact = contact[0];

                let data = contact.data;

                this.model = {
                    _id: contact._id,
                    type: (data && data.type) || [],
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    email: contact.emails[0],
                    phoneNumber: contact.phoneNumbers[0],
                    address: data && data.address,
                    country: data && data.country,
                    studentAward: data && data.studentAward,
                    masterAward: data && data.masterAward,
                    facebook: data && data.facebook,
                    twitter: data && data.twitter,
                    instagram: data && data.instagram,
                    website: data && data.website,
                    other: data && data.other,
                    grandMasterAward: data && data.grandMasterAward,
                    entrant: (data && data.entrant) || "Australian Entrant",
                };
            } catch (err) {
                console.log("ERROR", err);
                this.model = {};
            }

            this.loading = false;
        },
        async save() {
            this.saving = true;

            let model = this.model;
            let user = this.$app.user;

            try {
                let contactPayload = {
                    firstName: model.firstName,
                    lastName: model.lastName,
                    emails: [model.email],
                    phoneNumbers: [model.phoneNumber],
                    data: {
                        type: model.type,
                        address: model.address,
                        country: model.country,
                        entrant: model.entrant,
                        facebook: model.facebook || "", // optional
                        twitter: model.twitter || "", // optional
                        instagram: model.instagram || "", // optional
                        website: model.website || "", // optional
                        other: model.other || "", // optional
                        personaId: user.persona,
                    },
                };

                if (this.hasKey("print")) {
                    contactPayload.data.studentAward = model.studentAward;
                    contactPayload.data.masterAward = model.masterAward;
                    contactPayload.data.grandMasterAward = model.grandMasterAward;
                } else {
                    contactPayload.data.studentAward = false;
                    contactPayload.data.masterAward = false;
                    contactPayload.data.grandMasterAward = false;
                }

                let contact = await Contact.update(model._id, contactPayload).then(({ data }) => data);
                console.log("UPDATED CONTACT", contact);

                let personaPayload = {
                    firstName: model.firstName,
                    lastName: model.lastName,
                    username: model.email,
                };

                let persona = await Persona.update(user.persona, personaPayload).then(({ data }) => data);
                console.log("UPDATED PERSONA", persona);

                this.$fluro.api.get("/session/refresh", { cache: false });
                await new Promise((res) => setTimeout(res, 100));
                this.$fluro.api.get("/session/refresh", { cache: false });
                await new Promise((res) => setTimeout(res, 100));
                this.$fluro.api.get("/session/refresh", { cache: false });
                await new Promise((res) => setTimeout(res, 100));
                await this.$fluro.api.get("/session/refresh", { cache: false });

                let session = await this.$fluro.auth.sync();

                console.log("SESSION", session);

                await this.$app.init(session);

                await this.init();
            } catch (err) {
                console.log("ERROR", err);
                this.$emit("error", err);
            }

            this.saving = false;
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>
